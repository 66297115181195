
import { defineComponent } from "vue";
import HeaderSection from "../HeaderSection.vue";
import Loader from "@/components/ui/Loader.vue";
import { Topic, TopicType } from "@toppick/common/build/interfaces";
import { TopicPageTab } from "@/interfaces/ui";
import { getTopicPicker } from "@toppick/common/build/api";
import { pickerItems } from "../start/Index.vue";
import "../../styles.css";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  emits: ["onDelete", "onShowAuthModal"],
  components: {
    HeaderSection,
    Loader,
  },
  data() {
    return {
      topic: null as Topic | null,
      isLoading: false,
      isPageLoading: true,
      isShowAuthModal: false,
      TopicPageTab,
    };
  },
  methods: {
    async loadData() {
      this.topic = await getTopicPicker(await getAuthToken(), {
        type: this.currentType,
        include_categories: true,
        include_info: true,
      });
    },
    async onClickNext() {
      this.isLoading = true;
      try {
        await this.loadData();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    onClose() {
      this.$router.push({
        name: "TopicPickerStart",
      });
    },

    toggleAuthModal() {
      this.isShowAuthModal = !this.isShowAuthModal;
    },
  },

  computed: {
    currentType(): TopicType {
      let type: TopicType = TopicType.Default;
      const item = pickerItems.find(
        (item) => item.query === this.$route.query.type
      );
      if (item) {
        type = item.type;
      }
      return type;
    },
    currentPickerTitle(): string {
      return pickerItems.find((item) => item.type === this.currentType)!.title;
    },
  },

  async mounted() {
    this.$watch(
      () => ({
        type: this.currentType,
      }),
      async () => {
        try {
          this.isPageLoading = true;
          await this.loadData();
          this.isPageLoading = false;
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
